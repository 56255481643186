@font-face {
    font-family: kidStyle;
    src: url(../fonts/levibrush.ttf);
}

@font-face {
    font-family: kidStyle2;
    src: url(../fonts/kids-style.woff);
}

@font-face {
    font-family: winterFlakes;
    src: url(../fonts/winterFlakes.ttf);
}

body,
html {
    height: 100%;
}

.mainStyle {
    width: 100vw;
    height: 100vh;
}

.headerStyle {
    font-family: kidStyle;
    color: #e87482;
    text-align: center;
    line-height: 20vh;
    background-color: #ffd685;
    font-size: 80px;
    font-size: 10vw;
    height: 20vh;
    width: 100vw;
    padding-top: 15px;
    padding-right: 2vw;
}

.headerImage {
    height: 15vh;
    vertical-align: middle;
    padding-left: 2vw;
}

.sidebarStyle {
    height: 80vh;
    width: 20vw;
    background: #74e8cb;
    float: left;
}

@media (min-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media (min-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
}
@media (min-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
    /* hi-res laptops and desktops */
}

@media screen and (max-width: 480px) {
    .sidebarButtonStyle {
        cursor: pointer;
        font-family: kidStyle2;
        height: 25%;
        width: 100%;
        color: #988cff;
        background-color: #74e8cb;
        text-align: center;
        line-height: 20vh;
        font-size: 18px;
    }

    .poemStyle {
        font-family: kidStyle2;
        font-size: 20px;
        line-height: 120%;
        height: 80vh;
        width: 100%;
        text-align: center;
        color: #ffffff;
        background: #988cff;
    }

    .poemBody {
        padding-top: 2vh;
        padding-bottom: 5vh;
    }

    .poemTitle {
        font-family: winterFlakes;
        color: #74e8cb;
        font-size: 25px;
    }

    .audio {
        width: 75%;
    }
}

@media screen and (min-width: 480px) {
    .sidebarButtonStyle {
        cursor: pointer;
        font-family: kidStyle2;
        height: 25%;
        width: 100%;
        color: #988cff;
        background-color: #74e8cb;
        text-align: center;
        line-height: 20vh;
        font-size: 22px;
    }

    .poemStyle {
        font-family: kidStyle2;
        font-size: 25px;
        line-height: 120%;
        height: 80vh;
        width: 100%;
        text-align: center;
        color: #ffffff;
        background: #988cff;
    }

    .poemBody {
        padding-top: 2vh;
        padding-bottom: 5vh;
    }

    .poemTitle {
        font-family: winterFlakes;
        color: #74e8cb;
        font-size: 35px;
    }
}

@media screen and (min-width: 768px) {
    .sidebarButtonStyle {
        cursor: pointer;
        font-family: kidStyle2;
        height: 25%;
        width: 100%;
        color: #988cff;
        background-color: #74e8cb;
        text-align: center;
        line-height: 20vh;
        font-size: 30px;
    }

    .poemStyle {
        font-family: kidStyle2;
        font-size: 25px;
        line-height: 120%;
        height: 80vh;
        width: 100%;
        text-align: center;
        color: #ffffff;
        background: #988cff;
    }

    .poemBody {
        padding-top: 2vh;
        padding-bottom: 5vh;
    }

    .poemTitle {
        font-family: winterFlakes;
        color: #74e8cb;
        font-size: 35px;
    }
}

@media screen and (min-width: 992px) {
    .sidebarButtonStyle {
        cursor: pointer;
        font-family: kidStyle2;
        height: 25%;
        width: 100%;
        color: #988cff;
        background-color: #74e8cb;
        text-align: center;
        line-height: 20vh;
        font-size: 30px;
    }

    .poemStyle {
        font-family: kidStyle2;
        font-size: 25px;
        line-height: 120%;
        height: 80vh;
        width: 100%;
        text-align: center;
        color: #ffffff;
        background: #988cff;
    }

    .poemBody {
        padding-top: 2vh;
        padding-bottom: 5vh;
    }

    .poemTitle {
        font-family: winterFlakes;
        color: #74e8cb;
        font-size: 35px;
    }
}

@media screen and (min-width: 1280px) {
    .sidebarButtonStyle {
        cursor: pointer;
        font-family: kidStyle2;
        height: 25%;
        width: 100%;
        color: #988cff;
        background-color: #74e8cb;
        text-align: center;
        line-height: 20vh;
        font-size: 30px;
    }

    .poemStyle {
        font-family: kidStyle2;
        font-size: 25px;
        line-height: 120%;
        height: 80vh;
        width: 100%;
        text-align: center;
        color: #ffffff;
        background: #988cff;
    }

    .poemBody {
        padding-top: 2vh;
        padding-bottom: 5vh;
    }

    .poemTitle {
        font-family: winterFlakes;
        color: #74e8cb;
        font-size: 35px;
    }
}

.landingStyle {
    height: 80vh;
    width: 80vw;
    color: #74e8cb;
    background: #988cff;
    font-size: 30px;
    line-height: 100%;
    float: right;
}

.landingTextStyle {
    padding: 10vh;
}

@media (min-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 0;
        padding-top: 5%;
        padding-bottom: 51.25%;
    }

    .video {
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 0;
        padding-top: 5%;
        padding-bottom: 51.25%;
    }

    .video {
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 0;
        padding-top: 5%;
        padding-bottom: 51.25%;
    }

    .video {
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 0;
        padding-top: 5%;
        padding-bottom: 51.25%;
    }

    .video {
        position: absolute;
        top: 10%;
        left: 20%;
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 0;
        padding-top: 5%;
        padding-bottom: 51.25%;
    }

    .video {
        position: absolute;
        top: 20%;
        left: 20;
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 1281px) {
    /* hi-res laptops and desktops */

    .musicStyle {
        height: 80vh;
        width: 80vw;
        color: #74e8cb;
        background: #988cff;
        text-align: center;
        font-size: 25px;
        line-height: 100%;
        float: right;
    }

    .container {
        position: relative;
        width: 80vw;
        height: 80vh;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .video {
        position: absolute;
        top: 10%;
        left: 20;
        width: 60%;
        height: 80%;
    }
}

.pictureStyle {
    height: 80vh;
    width: 80vw;
    color: #74e8cb;
    background: #988cff;
    text-align: center;
    font-size: 25px;
    line-height: 100%;
    float: right;
}

.picture {
    padding-top: 5%;
}

.wordSelect {
    font-family: winterFlakes;
    color: #74e8cb;
    font-size: 27px;
}

@keyframes finalWord {
    0% {
        font-size: 25px;
    }
    100% {
        font-size: 35px;
    }
}

.wordSelect1 {
    font-family: winterFlakes;
    color: #74e8cb;
    animation: finalWord 3s forwards;
}
